@media only screen and (max-width: 480px) {
  .subTitleRsp {
    width: 300px !important;
  }
}

#loader {
  border: 6px solid #ffcc00;
  border-top: 6px solid purple;
  border-bottom: 6px solid purple;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: auto;
  position: fixed;
  top: 500px;
  left: 50%;
  margin-left: -21px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
