.masterContainer {
  margin: 0px;
  padding: 0px;
}

.mainContainer {
  margin-left: 80px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.navButton {
  width: 100px;
  margin: 15px;
}

.photosContainer {
  display: flex;
}

input:focus {
  outline: none
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

#loaderImg {
  border: 6px solid #ffcc00;
  border-top: 6px solid purple;
  border-bottom: 6px solid purple;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media only screen and (min-width: 380px) and (max-width: 480px) {
  .navButton {
    width: 100px;
    margin: 15px 0px;
  }
  .mainContainer {
    margin-left: 40px !important;
    margin-top: 40px !important;
  }
  .photosContainer {
    display: block;
  }
  .usersContainer {
    width: 400px !important;
  }
  .userContainer {
    margin: 5px;
  }
  .userBox {
    width: 180px !important;
    height: 225px !important;
  }
  .userName {
    font-size: 15px !important;
  }
  .calendarBox {
    width: 16px;
    height: 16px;
    padding: 6px !important;
  }
  .datesContainer {
    margin: 15px;
  }
  .datesPagePartition {
    display: block !important;
  }
  .datesLeftPartition {
    margin: 20px 30px !important;
  }
  .datesRightPartition {
    margin: 20px 30px !important;
  }
  .setAvailabilityBox {
    margin: 0px !important
  }
  .hoursInput {
    padding-left: 30px !important;
  }
  .availInput {
    padding-left: 30px !important;
    max-width: 140px !important;
    min-width: 120px !important;
  }
  .pagePartition {
    display: block !important;
  }
  .rightPartition {
    margin-top: 40px;
    padding: 20px 0px !important;
  }
  .memberPhotoA {
    width: 380px !important;
  }
  .memberPhotoB {
    width: 120px !important;
  }
  .memberAttributesBox {
    width: 380px;
  }
  .viewMemberContainer {
    margin: 20px !important;
    margin-bottom: 100px !important;
  }
}

@media only screen and (max-width: 380px) {
  .navButton {
    width: 90px;
    margin: 15px 0px;
  }
  .mainContainer {
    margin-left: 40px !important;
    margin-top: 40px !important;
  }
  .photosContainer {
    display: block;
  }
  .usersContainer {
    width: 360px !important;
  }
  .userContainer {
    margin: 5px;
  }
  .userBox {
    width: 160px !important;
    height: 200px !important;
  }
  .userName {
    font-size: 14px !important;
  }
  .calendarBox {
    width: 16px;
    height: 16px;
    padding: 5px !important;
  }
  .datesContainer {
    margin: 15px;
  }
  .datesPagePartition {
    display: block !important;
  }
  .datesLeftPartition {
    margin: 20px 0px !important;
  }
  .datesRightPartition {
    margin: 20px 0px !important;
  }
  .setAvailabilityBox {
    margin: 0px !important
  }
  .hoursInput {
    padding-left: 30px !important;
  }
  .availInput {
    padding-left: 30px !important;
    max-width: 140px !important;
    min-width: 120px !important;
  }
  .pagePartition {
    display: block !important;
  }
  .rightPartition {
    margin-top: 40px;
    padding: 20px 0px !important;
  }
  .memberPhotoA {
    width: 320px !important;
  }
  .memberPhotoB {
    width: 100px !important;
  }
  .memberAttributesBox {
    width: 320px;
  }
  .viewMemberContainer {
    margin: 20px !important;
    margin-bottom: 100px !important;
  }
  .faqButton {
    right: 30px !important;
    bottom: 30px !important;
  }
}